<template>
  <section class="page-landmark">
    <div class="banner">
      <p class="title">JWP DEVELOPER</p>
      <p class="sub-title">Building A Better World With Us</p>
    </div>
    <div class="parts">
      <p class="title">
        <span class="van-hairline--bottom">Six real estate sectors</span>
      </p>
      <a href="/landmark/cultural-tourism">
        <div class="item">
          <div class="bottom">
            <div class="name">
              Cultural Tourism
              <img
                class="more"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/jinru.png"
              />
            </div>
            <div class="desc">
              JWP Cultural Tourism consists of professional teams that enrich
              every aspect of a project through product positioning. R&D design,
              engineering, construction, and operations.
            </div>
          </div>
          <div class="">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tupian1.png"
            />
          </div>
        </div>
      </a>
      <a href="/landmark/residence">
        <div class="item">
          <div class="bottom">
            <div class="name">
              Residential Housing
              <img
                class="more"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/jinru.png"
              />
            </div>
            <div class="desc">
              Residential Housing is one of the earliest development secto under
              the JWP brand, being the most complete and having variousproperty
              designs and types to offer.
            </div>
          </div>
          <div class="">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tupian2.png"
            />
          </div>
        </div>
      </a>
      <a href="/landmark/commercial">
        <div class="item">
          <div class="bottom">
            <div class="name">
              Nursing Home
              <img
                class="more"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/jinru.png"
              />
            </div>
            <div class="desc">
              Promoting diversified elderly care services To alleviate the
              shortage of beds, obsolete facilities and slow technological
              update in nursing homes.
            </div>
          </div>
          <div class="">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tupian3.png"
            />
          </div>
        </div>
      </a>
      <a href="/landmark/student-apartment">
        <div class="item">
          <div class="bottom">
            <div class="name">
              Students Apartment
              <img
                class="more"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/jinru.png"
              />
            </div>
            <div class="desc">
              To provide students with future learning, life, situational living
              experienceinsocialscene.
            </div>
          </div>
          <div class="">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tupian4.png"
            />
          </div>
        </div>
      </a>
      <a key="" href="/landmark/land">
        <div class="item">
          <div class="bottom">
            <div class="name">
              Land Reserve
              <img
                class="more"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/jinru.png"
              />
            </div>
            <div class="desc">
              A powerful guarantee for the sustainable development of
              corporation.
            </div>
          </div>
          <div class="">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tupian5.png"
            />
          </div>
        </div>
      </a>
      <a href="/landmark/property-services">
        <div class="item">
          <div class="bottom">
            <div class="name">
              Property
              <img
                class="more"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/jinru.png"
              />
            </div>
            <div class="desc">
              Based on demand, focused on products, emphasison services.
            </div>
          </div>
          <div class="">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tupian6.png"
            />
          </div>
        </div>
      </a>
    </div>

    <div class="advantages">
      <p class="title">
        <span class="van-hairline--bottom"
          >Five Advantages of JWP Real Estate</span
        >
      </p>
      <div class="scroll-wrap">
        <ul class="boxs">
          <li class="box">
            <div class="inner">
              <van-image
                width="50"
                height="50"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tubiao1.png"
              />
              <p class="kw">Global Real Estate Brand</p>
              <p class="desc">
                <span>
                  One of the earliest real estate development companies to excel
                  in its expansion within the industries of international real
                  estate
                </span>
              </p>
            </div>
          </li>
          <li class="box">
            <div class="inner">
              <van-image
                width="50"
                height="50"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tubiao2.png"
              />
              <p class="kw">System of Operations</p>
              <p class="desc">
                <span
                  >Operational infrastructure that is responsive to market
                  demands and paired with excellent sales ability</span
                >
              </p>
            </div>
          </li>
          <li class="box">
            <div class="inner">
              <van-image
                width="50"
                height="50"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tubiao3.png"
              />
              <p class="kw">Land Reserves</p>
              <p class="desc">
                <span
                  >Expansive list of real estate inreserve. Selecting only the
                  most valued areas for development</span
                >
              </p>
            </div>
          </li>
          <li class="box">
            <div class="inner">
              <van-image
                width="50"
                height="50"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tubiao4.png"
              />
              <p class="kw">Systematic Planning and Design</p>
              <p class="desc">
                <span>
                  Projects are mainly based on high-endproducts emphasizing high
                  quality assetss with strong profitability
                </span>
              </p>
            </div>
          </li>
          <li class="box">
            <div class="inner">
              <van-image
                width="50"
                height="50"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/tubiao5.png"
              />
              <p class="kw">Global Service Team</p>
              <p class="desc">
                <span>
                  More than ten years global service experience Professional
                  management teams provide full range of services
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="map">
      <p class="title">
        <span class="van-hairline--bottom">Nine layout countries</span>
      </p>
      <van-image
        lazy-load
        src="//static.hoolihome.com/global-jwp/assets/m/landmark/map-en.png"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 1,
    };
  },
  beforeCreate() {
    document.title = "Real Estate Overview";
  },
};
</script>

<style lang="less" scoped>
.page-landmark {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 158px;
    background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/landmark/bg.png")
      no-repeat center center / cover;
    .title {
      margin-top: 22px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
    .sub-title {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
  .parts {
    padding: 24px 0 40px;
    .title {
      padding-bottom: 7px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .item {
      position: relative;
      margin: 10px 18px 0;
      .img {
        width: 100%;
        display: block;
      }
      .bottom {
        padding: 10px 10px;
        background: rgba(0, 0, 0, 0.35);
        position: absolute;
        bottom: 0;
        color: #fff;
        border-radius: 0% 0% 4px 4px;
        .name {
          display: flex;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          line-height: 20px;
          display: flex;
          align-items: center;

          .more {
            height: 10px;
            margin-left: 6px;
          }
        }
        .desc {
          font-weight: 400;
          font-size: 11px;
          color: #ffffff;
          line-height: 13px;
        }
      }
    }
  }
  .advantages {
    padding-bottom: 40px;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .scroll-wrap {
      width: 100vw;
      overflow-x: scroll;
      .boxs {
        display: flex;
        padding-left: 22px;
        .box {
          flex-shrink: 0;
          padding-right: 18px;
          padding-bottom: 4px;
          overflow-y: hidden;
          &:last-child {
            padding-right: 22px;
          }
          .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 161px;
            height: 214px;
            padding-top: 20px;
            border-radius: 4px;
            background: #e4edfc;
            .kw {
              height: 25px;
              margin-top: 10px;
              font-weight: 600;
              font-size: 12px;
              color: #252c39;
              line-height: 15px;
              text-align: center;
            }
            .desc {
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              padding: 0 5px;
              font-size: 11px;
              font-weight: 300;
              color: #4e4e4e;
              text-align: center;
              span {
                display: inline-block;
                line-height: 17px;
              }
            }
          }
        }
      }
    }
  }
  .map {
    padding-bottom: 46px;
    text-align: center;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .van-image {
      width: 335px;
    }
  }
}
</style>
